.slider--slidecontainer {
    width: 80%;
    margin-top: 30px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  
  .slider--slider {
    margin-top: 30px;
    -webkit-appearance: none;
    width: 300px;
    height: 5px;
    border-radius: 15px;
    background: #ffffff;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-right: auto;
    margin-left: auto;
  }
  
  .slider--slider:hover {
    opacity: 1;
  }
  
  .slider--slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #22b24c;
    cursor: pointer;
  }
  
  .slider--slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #22b24c;
    cursor: pointer;
  }

.slider--slidecontainer h2{
  color:#fff;
  text-align: center;
  font-family: 'Fjalla One', sans-serif;
}

.slider--slidecontainer h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 320px;
  color: #31dd65;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45px;
  font-size: 30px;
  font-family: 'Fjalla One', sans-serif;
}

.slider--button{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #22b24c;
  margin: 50px auto;
  width: 150px;
  height: 40px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #fff;
  clear: both;
}

@media (hover: hover) {
.slider--button:hover{
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
  cursor: grab;
}}

.slider--button:active{
  background-color: #283a41;
  border: none;
}

.slider--button p{
  font-family: 'Poppins', sans-serif;
  font-size: 150%;
}

.slider--min--max{
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

.slider--min{
  display: inline-block;
  float: left;

}
.slider--max{
  display: inline-block;
  float: right;
}

@media screen and (min-width: 1000px){

  .slider--slidecontainer h2{
      font-size: xx-large;
  }
}