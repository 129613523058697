.logo{
    display: block;
    width: 200px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
}

hr{
    margin-top: 15px;
    width: 100%;
}

.confirmation-container{
    text-align: center;
}

.confirmation-container h1{
    font-family: 'Poppins', sans-serif;
    color: #b0a690;
    margin-bottom: 0px;
}

.confirmation-container h2{
    margin-top: 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 115%;
    color: #b0a690;
}

.confirmation-container h3{
    font-family: 'Poppins', sans-serif;
    font-size: 75%;
    color: #aaa;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.confirmation-container p{
    color: #aaa;
    font-family: 'Poppins', sans-serif;
    font-size: 70%;
}

.confirmation-vector{
    width: 200px;
    margin-bottom: 20px;
}

.bounce-top {
	-webkit-animation: bounce-top 0.9s both;
	        animation: bounce-top 0.9s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-25 12:44:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
              transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
              transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
              transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
              transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
              transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  
