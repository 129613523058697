.modalText {
    width: 100%;
    height: 90%;
    overflow-y: auto;
    display: block;
    margin-right: auto;
    margin-right: auto;
    text-align: center;
    border-radius: 10px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.modalText::-webkit-scrollbar {
    display: none;
  }

.modalButton{
    background-color: limegreen;
    width: 50%;
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
    color: #fff;
    margin-top: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}

.modalButton:hover{
    color: limegreen;
    background-color: #fff;
    cursor: pointer;
}

.modalButton:active{
    color: limegreen;
    background-color: #fff;
    cursor: pointer;
}

img{
    width: 20%;
}

.modalText p{
    font-size: small;
}