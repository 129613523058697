.nothanks-container{
    text-align: center;
    width: 320px;
    margin: 50px auto;
    padding: 50px 0px;
    display: flex;
    justify-content: center;
}

.nothanks-container h2{
    font-size: 2rem;
    color: #ff9900;
    font-family: 'Pipppins', sans-serif;
    margin-bottom: 0;
}

.nothanks-container p{
    font-size: 0.9rem;
    color: #fff;
    font-family: 'Pipppins', sans-serif;
    text-align: center;
}