.footer{
    display: block;
    margin-left: auto;
    margin-right: auto; 
    height: 200px;
    width: 90%;
}

.phone{
    color: #999999;
    text-align: center;
    font-size: 1.6em;
    font-family: 'Pippins', sans-serif;
}

.phone span{
    color:limegreen;
}

.phone span a:link, a:visited{
    color: limegreen;
    text-decoration: none;
}

.phone span a:hover, a:active {
    color:linen;
}

.links{
    color: #999999;
    text-align: center;
    font-size: 0.88em;
}

.links span{ 
    color:limegreen;
    display: inline-block;
}

.links span:hover{
    color: #fff;
    cursor: pointer;
}

.disclosure{
    color: #999999;
    font-size: 0.8em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 30px;
}

@media screen and (min-width:1000px){
    .footer{width: 1000px;}
}