.back--button{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 40px;
    margin: 10px auto;
    background-color: #283a41;
    color: #acacac;
    border-radius: 15px;
}

@media (hover: hover) {
.back--button:hover{
    background-color: #acacac;
    color: #283a41;
    cursor: pointer;
}
}

.back--button:active{
    cursor: grabbing;
    outline: solid 10px #283a41;
}

.back--button h3{
    font-family: 'Poppins', sans-serif;
    margin: 0 auto;
}

.mainContainer{
    margin-bottom: 100px;
}

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.container {
  color: #FFF;
  max-width: 850px;
  margin: 10px auto;
  h1 {
    text-align: center;
  }
  .progressbar-container {
    position: relative;
    width: 100%;
    height: 20px;
    border: 1px solid rgba(255, 255, 255, 0.527);
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #FFF;
    .progressbar-complete {
      position: absolute;
      left: 0;
      top: 0px;
      height: 100%;
      background-color: #5225bd;
      border-radius: 0px;
      animation: g 2500ms infinite ease-in-out;
      z-index: 2;
      .progressbar-liquid {
        z-index: 1;
        width: 70px;
        height: 70px;
        animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
        position: absolute;
        right: -5px;
        top: -10px;
        background-color: #46b847;
        border-radius: 40%;
      }
    }
    .progress {
      z-index: 2;
      font-family: 'Poppins', sans-serif;
    }
  }
}

@keyframes g {
  0% { background-color: #46b847; }
  50% { background-color: #46b847; }
  100% { background-color: #46b847; }
}

@keyframes r {
  from { transform: rotate(0deg); }
  from { transform: rotate(360deg); }
}