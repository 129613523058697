.partner-section-container{
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.partner-section-container img{
    width: 150px;
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    padding: 15px 15px;
}

.partner-section-container h2{
    color: #aaaaaa;
    font-family: 'Pippins', sans-serif;
}