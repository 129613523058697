.card {
    background-color: #ffffff;
    width: 110px;
    height: 110px;
    border-radius: 15px;
    border: solid 5px #ddd;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: inline-block;
    margin: 10px 10px;
    transition: transform 250ms;
}

.card-touch{
    border-color: limegreen;
}

@media (hover: hover) {
.card:hover{
    border: solid 5px #46b847;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
    cursor: grab;
    transform: translateY(-10px);
}
}

.card:active{
    cursor: grabbing;
    border: solid 5px #46b847;
    transform: translateY(-10px);
}

.card-img{
    margin-left: auto;
    margin-right: auto;
    margin-top:10%; 
    width: 50px;
    height: 50px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
}

.card-p{
    width: 80%;
    height: 40px;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    color: #fa7000;
    margin: 0 auto;
    font-size: 75%;
    overflow: hidden;
}

.card-container {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    flex-flow: row wrap; 
    justify-content: center;
}

.icons-container{
    margin-top: 30px;
    width: 80%;
    margin-left:auto;
    margin-right: auto;
}

.icons-container h2{
    text-align: center;
    font-size: 1.5em;
    color: rgb(255, 255, 255);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Fjalla One', sans-serif;
}

@media screen and (min-width: 1000px){

    .icons-container h2{
        font-size: xx-large;
    }

    .card{
        width: 180px;
        height: 180px;
    }

    .card-img{
        width: 100px;
        height: 100px;
    }

    .card-p{
        font-size: 100%;
        height: 60px;
    }
}