.testimonials-container{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    text-align: center;
    display: block;
}

.testimonials-container-2{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}


.testimonials-title{
    margin-top: 70px;
    margin-bottom: 30px;
    color: #aaaaaa;
    font-family: 'Pippins', sans-serif;
    text-align: center;
    display: inline-block;
}

.testimonials-button{
    display: inline-block;
    color: limegreen;
    padding: 0px 7px;
    cursor: pointer;
}

.testimonials-button:hover{
    color:#fff;
}

.active{
    color: #15963c;
    background-color: #fff;
}

.active:hover{
    color: #fff;
    background-color: #15963c;
}

.testimonials-container h4{
    color: #FFBD38;
    font-family: 'Pippins', sans-serif;
    margin: 0;
    position: relative;
    right: 10px;
    text-align: right;
}

.testimonials-container p{
    display: block;
    border: solid 2px #FFBD38;
    font-family: 'Pippins', sans-serif;
    width: 300px;
    color: #222;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px;
    text-align: justify;
    border-radius: 5px;
    margin-bottom: 30px;
    white-space: normal;
    background-color: white;
}

.testimonials-container p span{
    display: block;
    text-align: center;
}

.testimonials-container p span img{
    width: 150px;
    margin-bottom: 10px;
}

.testimonial{
    height: 500px;
}

.testimonials-control-buttons > *{
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}
.testimonials-control-buttons > *:hover{
    color: orange;
    cursor: pointer;
}

.pause{
    color: red;
}

.unpause{
    color: white;
}

.testimonials-control-buttons{
    margin-bottom: 10px;
}