.container{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.container h2{
    text-align: center;
    font-size: 1.5em;
    color: rgb(255, 255, 255);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Fjalla One', sans-serif;
}

.error-p{
    font-family: 'Poppins', sans-serif;
    color: #ff2300;
    text-align: center;
    font-size: 105%;
}