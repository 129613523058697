.button--grid--container{
    margin-top: 30px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.button--grid--container h2{
    text-align: center;
    font-size: 1.5em;
    color: rgb(255, 255, 255);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Fjalla One', sans-serif;
}

.button--grid--button{
    background-color: #fff;
    width: 250px;
    height: 40px;
    margin: 20px auto;
    text-align: center;
    border-radius: 15px;
    border: solid 5px #dddddd;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: transform 250ms;
}

@media (hover: hover) {
.button--grid--button:hover{
    border: solid 5px #46b847;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19);
    cursor: grab;
    transform: translateY(-10px);
}
}

.button--grid--button:active{
    cursor: grabbing;
    border: solid 5px #46b847;
    transform: translateY(-10px);
}

.button--grid--button p{
    width: 90%;
    height: 40%;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    color: #fa7000;
    margin: 0 auto;
    font-size: 75%;
    margin-top: 10px;
}

@media screen and (min-width: 1000px){
    .button--grid--container{
        height: 500px;
    }
    .button--grid--container h2{
        font-size: xx-large;
    }

    .button--grid--button{
        width: 340px;
        height: 60px;
        margin-bottom: 0px;
        border-radius: 25px;
    }

    .button--grid--button p{
        margin-top: 18px;
        font-size: 100%;
        height: 60px;
        width: 90%;
    }
}